export const apiEndpoint = {
  data() {
    return {
      // BEE_FILE_URL: "http://127.0.0.1:8000",
      BEE_FILE_URL: "https://blims-api-stage.tappware.com",
      VUE_APP_API_URL: "http://cpv-api.skydigitalbd.com",
      VUE_APP_TMS: "http://cpv-app.skydigitalbd.com",
      VUE_APP_DOPTOR: "https://seip-doptor.tappware.com",
      VUE_APP_EMPLOYEE_SHOW: "employee/show",
      VUE_APP_DASHBOARD_DATA: "dashboard/dashboard-data",
      VUE_APP_DASHBOARD_DISTRICT_WISE_GENDER: "dashboard/district-wise-beneficiary-gender",
      VUE_APP_DASHBOARD_YEAR_WISE_GENDER: "dashboard/year-wise-beneficiary-gender",
      VUE_APP_DASHBOARD_EMPLOYMENT_TYPE_WISE_GENDER: "dashboard/employment-type-wise-beneficiary-gender",
      VUE_APP_DASHBOARD_EVENT_WISE_GENDER: "dashboard/district-wise-event-gender",
      VUE_APP_DASHBOARD_SECTOR_OCCUPATION_WISE_BENEFICIARY: "dashboard/sector-occupation-wise-beneficiary",
      VUE_APP_DASHBOARD_TRAINING_INSTITUTE_WISE_BENEFICIARY: "dashboard/training-institute-wise-beneficiary",
      VUE_APP_DASHBOARD_TRAINING_INSTITUTE_EVENT_WISE_BENEFICIARY: "dashboard/training-institute-event-wise-beneficiary",
      VUE_APP_DASHBOARD_TRAINING_TYPE_WISE_EVENT: "dashboard/training-type-wise-event",
      VUE_APP_DASHBOARD_TRAINING_TYPE: "dashboard/training-type",
      VUE_APP_DASHBOARD_DISTRICT_WISE_EVENT: "dashboard/district-wise-event",
      VUE_APP_DASHBOARD_ILO_TOOLS_TRAINING: "dashboard/ilo-tools-and-training-introduced",
      VUE_APP_DASHBOARD_INNOVATE_TECHNOLOGY_ESHTABLISH : "dashboard/innovative-technology-established",
      VUE_APP_DASHBOARD_HOUSEHOLD: "dashboard/household-data",
      VUE_APP_COMPONENT_LIST: "component/list",
      VUE_APP_OUTPUT_LIST: "output/list",
      VUE_APP_OUTPUT_STORE: "output/store",
      VUE_APP_OUTPUT_DELETE: "output/delete-output",
      VUE_APP_ACTIVITY_LIST: "activity/list",
      VUE_APP_ACTIVITY_STORE: "activity/store",
      VUE_APP_ACTIVITY_DELETE: "activity/delete-activity",
      VUE_APP_ACTIVITY_PROGRESS_LIST: "activity/progress-list",
      VUE_APP_ACTIVITY_PROGRESS_STORE: "activity/progress-store",
      VUE_APP_ACTIVITY_PROGRESS_DELETE: "activity/delete-progress",
      VUE_APP_ACTIVITY_PROGRESS_EXPORT: "activity/activity-progress-export",
      VUE_APP_TASK_LIST: "task/list",
      VUE_APP_TASK_STORE: "task/store",
      VUE_APP_TASK_DELETE: "task/delete-task",
      VUE_APP_TASK_ASSIGN: "task/assign",
      VUE_APP_BENEFICIARY_LIST: "beneficiary/list",
      VUE_APP_BENEFICIARY_STORE: "beneficiary/store",
      VUE_APP_BENEFICIARY_INFO: "beneficiary/show",
      VUE_APP_BENEFICIARY_DELETE: "beneficiary/delete-beneficiary",
      VUE_APP_IMPORT_BENEFICIARY_LIST: "beneficiary/import-beneficiary-list",
      VUE_APP_EXPORT_BENEFICIARY_LIST: "beneficiary/export-beneficiary-list",
      VUE_APP_MENU_WITH_ACTION_API: "menu/list",
      VUE_APP_IAPARTNER_LIST: "ia_partner/list",
      VUE_APP_IAPARTNER_STORE: "ia_partner/store",
      VUE_APP_IAPARTNER_DELETE: "ia_partner/delete-ia-partner",
      VUE_APP_IAPARTNER_WORKPLAN_PERMISSION: "ia_partner/work-plan-permission",
      VUE_APP_OCCUPATION_LIST: "occupation/list",
      VUE_APP_OCCUPATION_STORE: "occupation/store",
      VUE_APP_OCCUPATION_DELETE: "occupation/delete-occupation",
      VUE_APP_EVENT_LIST: "event/list",
      VUE_APP_EVENT_STORE: "event/store",
      VUE_APP_EVENT_INFO: "event/show",
      VUE_APP_EVENT_DELETE: "event/delete-event",
      VUE_APP_IMPORT_EVENT_LIST: "event/import-event-list",
      VUE_APP_EXPORT_EVENT_LIST: "event/export-event-list",
      VUE_APP_IPWORKPLAN_LIST: "ip-work-plan/list",
      VUE_APP_IPWORKPLAN_STORE: "ip-work-plan/store",
      VUE_APP_IPWORKPLAN_SHOW: "ip-work-plan/show",
      VUE_APP_IPWORKPLAN_EXPORT: "ip-work-plan/export",
      VUE_APP_IPWORKPLAN_DELETE: "ip-work-plan/delete-work-plan",
      VUE_APP_TOOLS_TRAINING_LIST: "tools-training/list",
      VUE_APP_TOOLS_TRAINING_STORE: "tools-training/store",
      VUE_APP_TOOLS_TRAINING_SHOW: "tools-training/show",
      VUE_APP_TOOLS_TRAINING_DELETE: "tools-training/delete-tools-training",
      VUE_APP_INNOVATIVE_TECHNOLOGY_LIST: "innovative-technology/list",
      VUE_APP_INNOVATIVE_TECHNOLOGY_STORE: "innovative-technology/store",
      VUE_APP_INNOVATIVE_TECHNOLOGY_SHOW: "innovative-technology/show",
      VUE_APP_INNOVATIVE_TECHNOLOGY_DELETE: "innovative-technology/delete-business-development",
      VUE_APP_BUSINESS_DEVELOPMENT_LIST: "business-development/list",
      VUE_APP_BUSINESS_DEVELOPMENT_STORE: "business-development/store",
      VUE_APP_BUSINESS_DEVELOPMENT_SHOW: "business-development/show",
      VUE_APP_BUSINESS_DEVELOPMENT_DELETE: "business-development/delete-innovative-technology",
      VUE_APP_ENTREPRENEURS_SUPPLY_CHAIN_LIST: "entrepreneurs-supply-chains/list",
      VUE_APP_ENTREPRENEURS_SUPPLY_CHAIN_STORE: "entrepreneurs-supply-chains/store",
      VUE_APP_ENTREPRENEURS_SUPPLY_CHAIN_SHOW: "entrepreneurs-supply-chains/show",
      VUE_APP_ENTREPRENEURS_SUPPLY_CHAIN_DELETE: "entrepreneurs-supply-chains/ddelete-entrepreneurs-supply-chains",
      VUE_APP_TOURISM_LIST: "tourism/list",
      VUE_APP_TOURISM_STORE: "tourism/store",
      VUE_APP_TOURISM_DELETE: "tourism/delete-tourism",
      VUE_APP_PPP_LIST: "public-private-partnership/list",
      VUE_APP_PPP_STORE: "public-private-partnership/store",
      VUE_APP_PPP_DELETE: "public-private-partnership/delete-tourism",
      VUE_APP_SOCIAL_MARKETING_CAMPAIGN_LIST: "social-marketing-campaign/list",
      VUE_APP_SOCIAL_MARKETING_CAMPAIGN_STORE: "social-marketing-campaign/store",
      VUE_APP_SOCIAL_MARKETING_CAMPAIGN_DELETE: "social-marketing-campaign/delete-tourism",
      VUE_APP_BUSINESSES_ESTABLISHED_LIST: "businesses-established/list",
      VUE_APP_BUSINESSES_ESTABLISHED_STORE: "businesses-established/store",
      VUE_APP_BUSINESSES_ESTABLISHED_DELETE: "businesses-established/delete-tourism",
      VUE_APP_DIVISION_LIST: "geo/division/list",
      VUE_APP_DISTRICT_LIST: "geo/district/list",
      VUE_APP_UPAZILA_LIST: "geo/upazila/list",
      VUE_APP_KNOWLEDGEMANAGEMENT_LIST: "knowledge-management/list",
      VUE_APP_KNOWLEDGEMANAGEMENT_STORE: "knowledge-management/store",
      VUE_APP_KNOWLEDGEMANAGEMENT_DELETE: "knowledge-management/delete-knowledge-management",
      VUE_APP_KNOWLEDGEMANAGEMENT_FILE_TYPE: "knowledge-management/get-knowledge-management-file-type",
      VUE_APP_NARRATIVE_REPORT_LIST: "narrative-reports/list",
      VUE_APP_NARRATIVE_REPORT_STORE: "narrative-reports/store",
      VUE_APP_NARRATIVE_REPORT_DELETE: "narrative-reports/delete-narrative-reports",
      VUE_APP_SUERVEY_LIST: "survey/list",
      VUE_APP_SUERVEY_STORE: "survey/store",
      VUE_APP_SUERVEY_DELETE: "survey/delete-survey",
      VUE_APP_SUERVEY_ANSWER_LIST: "survey/answer-list",
      VUE_APP_SUERVEY_ANSWER_SHEET: "survey/answer-sheet",
      VUE_APP_QUESTION_LIST: "question/list",
      VUE_APP_QUESTION_STORE: "question/store",
      VUE_APP_QUESTION_DELETE: "question/delete-question",
      VUE_APP_PUBLIC_SURVEY_WISE_QUESTIONS: "public/survey-wise-questions",
      VUE_APP_PUBLIC_SURVEY_STORE: "public/survey-store",
      VUE_APP_EVENT_REPORT: "reports/event-report",
      VUE_APP_EVENT_REPORT_EXPORT: "reports/event-report",
      VUE_APP_QUARTERLY_REPORT_EXPORT: "reports/quarterly-report-export",
      VUE_APP_ENTITY_TYPE_ROLE_UPDATE: "entity_type_role/role/update",
      VUE_APP_ENTITY_TYPE_ROLE_SAVE: "entity_type_role/role/save",
      VUE_APP_ENTITY_TYPE_ROLE_GET_BY_ID: "entity_type_role/role/getsinglerole",
      VUE_APP_USER_LIST: "users/list",
      VUE_APP_USER_STORE: "users/store",
      VUE_APP_USER_INFO: "users/show",
      VUE_APP_USER_DELETE: "users/delete-user",
    };
  },
  methods: {
    numberWithCommas(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
};
